import { Box, Form, Text } from "grommet";
import React, { useRef, useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Auth, Button, TextInput } from "../../components";
import { LogoImage } from "../../components/auth";
import { FlexBar } from "../../components/shared/style";
import useAPI from "../../lib/api/useApi";
import { Constant, isMobile } from "../../lib/Constant";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 50%;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const SignInLabel = styled(Text)`
  font-weight: 500;
  font-size: 1.25em;
  margin-bottom: 2.5em;
  text-align: center;
`;

const LoginWrap = styled(Box)`
  margin: auto;
  max-width: 280px;
`;

const Login = () => {
  const navigate = useNavigate();
  const { POST, GET } = useAPI();
  const [formValues, setFormValues] = useState({
    phoneNumber: "",
  });
  const mutation = useMutation("login", POST);
  const onFormChange = (value) => {
    setFormValues(value);
  };
  const [searchParams] = useSearchParams();
  let [cardNumber, setCardNumber] = useState(searchParams.get("c"));
  const mRef = useRef(0);

  useEffect(() => {
    function handleEvent(message) {
      mRef.current = 0;
      setCardNumber(message.data);
    }
    document.addEventListener("message", handleEvent);

    return () => document.removeEventListener("message", handleEvent);
  }, []);

  useQuery(
    ["cardDetail", cardNumber],
    async () => {
      if (mRef.current === 0 && cardNumber) {
        mRef.current = 1;
        return await GET({
          path: `auth/${cardNumber}`,
          spin: true,
          isErrorToast: true,
        });
      } else {
        return undefined;
      }
    },
    {
      onSettled: (res) => {
        if (res?.is_success) {
          sendOtpFunc(res?.data?.mobileNumber);
        }
      },
    }
  );

  const sendOtpFunc = (phoneNumber) => {
    mutation.mutate(
      {
        path: "auth/send-otp",
        isApiToast: true,
        body: {
          mobileNumber: phoneNumber || formValues.phoneNumber,
        },
      },
      {
        onSettled: (res) => {
          if (res.data?.is_success) {
            navigate("/verify-otp", {
              state: { phoneNumber: phoneNumber || formValues.phoneNumber },
            });
          }
        },
      }
    );
  };

  const onSubmit = async () => {
    sendOtpFunc();
  };

  return (
    <Auth
      isLogin={true}
      rightScreen={
        <Wrapper>
          {isMobile && (
            <FlexBar style={{ marginBottom: 24 }}>
              <LogoImage src={Constant.Icons.mypropertyLogo} />
            </FlexBar>
          )}
          <h1>Sign In</h1>
          <SignInLabel>Please sign in to continue</SignInLabel>
          <Form
            onSubmit={({ value, touched }) => onSubmit({ value, touched })}
            value={formValues}
            onChange={onFormChange}
            method="post"
            validate="blur"
            style={{ width: "100%" }}
          >
            <LoginWrap>
              <TextInput
                regEx={{ pattern: "^[0-9]{10}$", message: "invalid" }}
                icon={<Text className="textIcon">+91</Text>}
                required
                id="phoneNumber"
                type="number"
                label="Phone Number"
              />
            </LoginWrap>
            <Box style={{ margin: "auto" }}>
              <Button
                disabled={mutation.isLoading}
                type="submit"
                primary
                label="Submit"
              />
            </Box>
          </Form>
        </Wrapper>
      }
    />
  );
};

export default Login;
